import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Title": "Desktop-as-a-Service without borders",
      "Welcome": "Soon you will find here information about DESIGN, a novel Desktop-as-a-Service solution with unique features that adapts to your needs.",
      "Funded": "DESIGN is a joint development of Nuromedia and Frankfurt University of Applied Sciences, funded by the Federal Ministry for Economic Affairs and Climate Action",
      "Error-Title": "Page not available",
      "Error-text-one": "This page does not exist or is not more available.",
      "Error-text-two": "Check the URL or click on the link on the bottom.",
      "language": "Language",
      "back-link": "Back",
      "login": "Login",
      "username": "Username",
      "password": "Password",
      "submit": "Submit",
      "error-username": "Username are wrong",
      "error-password": "Password are wrong",
      "error-fullname": "Fullname are wrong",
      "error-email": "Email are wrong",
      "logout": "Logout",
      "overview": "Overview - Applications/Systems",
      "monitoring-overview": "Monitoring - Overview",
      "monitoring": "Monitoring",
      "create-user": "Create an account",
      "information": "Information",
      "delete": "Delete",
      "yes": "Yes",
      "no": "No",
      "email": "Email",
      "fullname": "Fullname",
      "user-management": "User management",
      "environment": "Environments",
      "linux": "Linux",
      "windows": "Windows",
      "docker": "Docker",
      "create-environment": "Create an environment",
      "start-environment": "Start an environment",
      "start-application": "Starting application",
      "go-to-snapshots": "Snapshots - Overview",
      "snapshots": "Snapshots",
      "choose": "Choose",
      "create-new-snapshots": "Create a new snapshot",
      "go-to-environment": "Environments - Overview",
      "create": "Create",
      "configurate": "Configurate",
      "finalize": "Finalize",
      "final-message": "Are you sure that everything is set correctly?",
      "start": "Start",
      "stop": "Stop",
      "open-application": "Open application",
      "delete-message": "Are you sure you want to delete this environment?",
      "create-environment-message": "Are you sure you want to create this environment?",
      "create-deployment-message": "Are you sure you want to create this deployment?",
      "create-deployment": "Create a new deployment",
      "prepare-app": "Prepare App",
      "run-app": "Run App",
      "run-message": "Are you sure you want to run this application?",
      "restart-app": "Restart app",
      "screenshot": "Screenshot",
      "attach-usb": "Attach USB",
      "users": "Users",
      "admins": "Admins",
      "user": "User",
      "expert": "Expert",
      "admin": "Admin",
      "all": "All",
      "enable": "Enable",
      "disable": "Disable",
      "active": "Active",
      "inactive": "Inactive",
      "group": "Group",
      "create-update-group": "Create/Update a group",
      "create-group": "Create a group",
      "update-group": "Update a group",
      "description-group": "Group description",
      "group-choice": "Group choice",
      "select-group": "Select a group",
      "vm-setup": "Virtual environment",
      "vm-create": "Create",
      "vm-delete": "Delete",
      "vm-start": "Start",
      "vm-stop": "Stop",
      "vm-list": "List",
      "vm-status": "Status",
      "vm-config": "Configuration",
      "vm-id": "ID",
      "vm-app-id": "App ID",
      "vm-cores": "Cores",
      "vm-memory-size": "Memory Size",
      "vm-disk-size": "Hard Disk Size",
      "vm-os-type": "Operation System",
      "vm-id-instance": "ID instance",
      "vm-name": "Name",
      "vm-iso-installer": "ISO Image",
      "vm-keyboard-language": "Keyboard (Language)",
      "vm-object-type": "Object type",
      "vm-desc": "Description",
      "vm-snapname": "Snapshot",
      "vm-rollback": "Rollback",
      "vm-new-id": "New ID",
      "vm-disk": "Disk",
      "vm-config-pre": "Pre",
      "vm-config-post": "Post",
      "vm-clone": "Clone",
      "vm-template-convert": "Template convert",
      "vm-node-dhcp": "DHCP",
      "vm-node-iptables": "IPTables",
      "vm-ceph-pool": "Ceph Pool",
      "vm-ceph-public": "Ceph Public",
      "vm-ceph-shared": "Ceph Shared",
      "vm-ceph-user": "Ceph User",
      "vm-env": "Environment",
      "vm-file": "File",
      "vm-execute": "Execute",
      "vm-connect": "Connection",
      "vm-kill": "Kill process",
      "vm-force": "Kill process",
      "vm-command": "Command",
      "vm-type": "Type",
      "vm-contype": "Connection type",
      "vm-resolution": "Resolution",
      "vm-resize": "Resize",
      "vm-scale": "Scale",
      "vm-arguments": "Arguments",
      "vm-mount": "Mount",
      "vm-unmount": "Unmount",
      "vm-instance": "Instances",
      "vm-instance-app": "Application",
      "vm-instance-command": "Command",
      "vm-instance-arguments": "Arguments",
      "vm-instance-ssh": "SSH",
      "vm-instance-status": "Action",
      "vm-instance-resize": "Resolution",
      "vm-instance-os-package": "Operation system - Package",
      "vm-instance-upload": "Upload",
      "vm-instance-mount-ceph": "Filesystem",
      "vm-instance-connection-icmp": "Connection - ICMP",
      "vm-instance-connection-ssh": "Connection - SSH",
      "vm-instance-install": "Install",
      "vm-instance-uninstall": "Uninstall",
      "vm-instance-list": "List",
      "vm-connection-icmp": "Connection - ICMP",
      "vm-connection-ssh": "Connection - SSH",
      "vm-docker": "Docker",
      "vm-dockerfile": "Dockerfile",
      "vm-root-image": "Root Image",
      "vm-docker-daemon-info": "Daemon info",
      "vm-docker-image-inspect": "Image inspect",
      "vm-docker-image-list": "Image list",
      "vm-docker-image-create": "Image create",
      "vm-docker-image-create-root": "Root Image create",
      "vm-docker-image-clone": "Image clone",
      "vm-docker-image-delete": "Image delete",
      "vm-docker-image-build": "Image build",
      "vm-docker-container-start": "Container start",
      "vm-docker-container-stop": "Container stop",
      "vm-docker-container-list": "Container list",
      "vm-docker-container-log": "Container logs",
      "vm-phases": "Phases",
      "vm-phases-object-status": "Object status",
      "vm-phases-object-list": "Object list",
      "vm-phases-environment-create": "Environment create",
      "vm-phases-environment-finalize": "Environment finalize",
      "vm-phases-environment-delete": "Environment delete",
      "vm-phases-environment-get": "Environment get",
      "vm-phases-environments-get": "Environments get",
      "vm-phases-environment-start": "Environment start",
      "vm-phases-environment-stop": "Environment stop",
      "vm-phases-environment-run": "Environment run",
      "vm-phases-cfg-from-application": "CFG from application",
      "vm-phases-cfg-tasklist": "CFG tasklist",
      "vm-phases-cfg-applist": "CFG applist",
      "vm-phases-cfg-target": "CFG target",
      "vm-phases-baseimage-create": "Baseimage create",
      "vm-phases-baseimage-clone": "Baseimage clone",
      "vm-phases-baseimage-create-from-app": "Baseimage create from app",
      "vm-phases-baseimage-clone-from-app": "Baseimage clone from app",
      "vm-phases-baseimage-finalize": "Baseimage finalize",
      "vm-phases-baseimage-delete": "Baseimage delete",
      "vm-phases-baseimage-start": "Baseimage start",
      "vm-phases-baseimage-stop": "Baseimage stop",
      "viewer-connection": "Connection start",
      "viewer-disconnection": "Connection stop",
      "viewer-settings": "Settings",
      "viewer-contype": "Connection type",
      "viewer-resolution": "Resolution",
      "viewer-check": "Connections",
      "authentification": "Authentification",
      "auth-token": "Token check",
      "auth-user-id": "User ID",
      "no-data-available": "No data available",
      "request-successful": "Request successful",
      "request-failed": "Request failed",
      "new-tab": "New tab",
      "new-window": "New window",
      "execute-command": "Execute command",
      "os_install": "Install",
      "os_uninstall": "Uninstall",
      "demo": "Demo",
      "demo-overview": "Demo - Overview",
      "desktops": "Desktops",
      "desktop-details": "Desktop details",
      "desktop-create": "Create a new desktop",
      "desktop-groups": "Desktop groups",
      "desktop-groups-create": "Create a new desktop group",
      "desktop-groups-main-description": "Desktop group main description",
      "desktop-main-description": "Desktop main description",
      "desktop-sub-description": "Desktop sub description",
      "desktop-groups-add-user-group": "Add user group to the desktop group",
      "add-user-group": "Add user group",
      "select-user-group": "Select a user group",
      "admin-assign-object-to-user": "Assign object to user",
      "admin-assign-app": "Assign app",
      "owner": "Owner",
      "admin-assign-object-app": "Assign objects and apps",
      "app-configuration": "Apps - Configuration",
      "app-list": "App list",
      "choice-list": "Choice list",
      "user-select": "User",
      "shared-select": "Shared",
      "both-select": "Both",
      "app-create": "Create app",
      "app-create-shared": "Create shared app",
      "app-update": "Update app",
      "app-update-shared": "Update shared app",
      "app-delete-shared": "Delete shared app",
      "app-delete": "Delete app",
      "app-get": "Get app",
      "app-create-update-name": "Name",
      "app-create-update-os-type": "Operation System",
      "app-create-update-installer": "Installer",
      "app-create-update-installer-args": "Installer arguments",
      "app-create-update-installer-type": "Installer type",
      "app-create-update-target": "Target",
      "app-create-update-target-args": "Target arguments",
      "app-create-update-version": "Version",
      "app-installer-none": "None",
      "app-installer-execute-command": "Execute command",
      "app-installer-os-install": "Operation system install",
      "app-installer-os-uninstall": "Operation system uninstall",
      "app-general": "Apps",
      "app-shared": "Shared apps",
      "app-request": "Request for app",
      "add-application": "Add application",
      "validate-registration": "Registration validation",
      "user-code": "Code",
      "request-message": "Request message"
    }
  },
  de: {
    translation: {
      "Title": "Desktop-as-a-Service ohne Grenzen",
      "Welcome": "In naher Zukunft finden Sie hier Informationen zu DESIGN, einer neuartigen Desktop-as-a-Service Lösung mit einzigartigen Features, die sich Ihren Anforderungen anpasst.",
      "Funded": "DESIGN ist eine gemeinsame Entwicklung von Nuromedia und der Frankfurt University of Applied Sciences, gefördert vom Bundesministerium für Wirtschaft und Klimaschutz",
      "Error-Title": "Seite nicht gefunden",
      "Error-text-one": "Diese Seite existiert nicht oder ist nicht mehr verfügbar.",
      "Error-text-two": "Überprüfe die URL oder klicke auf den Link unten.",
      "language": "Sprache",
      "back-link": "Zurück",
      "login": "Anmelden",
      "username": "Benutzername",
      "password": "Passwort",
      "submit": "Bestätigen",
      "error-username": "Benutzername ist nicht korrekt",
      "error-password": "Passwort ist nicht korrekt",
      "error-fullname": "Vollständiger Name ist nicht korrekt",
      "error-email": "E-Mail ist nicht korrekt",
      "logout": "Abmelden",
      "overview": "Übersicht - Anwendungen/Systeme",
      "monitoring-overview": "Überwachung - Übersicht",
      "monitoring": "Überwachung",
      "create-user": "Erstelle ein Konto",
      "information": "Informationen",
      "delete": "Löschen",
      "yes": "Ja",
      "no": "Nein",
      "email": "E-Mail",
      "fullname": "Vollständiger Name",
      "user-management": "Benutzerverwaltung",
      "environment": "Umgebungen",
      "linux": "Linux",
      "windows": "Windows",
      "docker": "Docker",
      "create-environment": "Erstelle eine Umgebung",
      "start-environment": "Starte die Umgebung",
      "start-application": "Starte die Anwendung",
      "go-to-snapshots": "Snapshots - Übersicht",
      "snapshots": "Snapshots",
      "choose": "Auswählen",
      "create-new-snapshots": "Erstelle einen neuen Snapshot",
      "go-to-environment": "Umgebungen - Übersicht",
      "create": "Erstelle",
      "configurate": "Konfiguriere",
      "finalize": "Finalisiere",
      "final-message": "Sind Sie sicher, dass alles richtig eingestellt ist?",
      "start": "Start",
      "stop": "Stop",
      "open-application": "Anwendung öffnen",
      "delete-message": "Sind Sie sicher, dass Sie diese Umgebung löschen wollen?",
      "create-environment-message": "Sind Sie sicher, dass Sie diese Umgebung schaffen wollen?",
      "create-deployment-message": "Sind Sie sicher, dass Sie diese Bereitstellung erstellen möchten?",
      "create-deployment": "Erstellen Sie eine neue Bereitstellung",
      "prepare-app": "Anwendung vorbereiten",
      "run-app": "Anwendung ausführen",
      "run-message": "Sind Sie sicher, dass Sie diese Anwendung starten wollen?",
      "restart-app": "Anwendung neu starten",
      "screenshot": "Bildschirmfoto",
      "attach-usb": "USB anschließen",
      "users": "Benutzer",
      "admins": "Administratoren",
      "user": "Benutzer",
      "expert": "Experte",
      "admin": "Admin",
      "all": "Alle",
      "enable": "Aktivieren",
      "disable": "Deaktivieren",
      "active": "Aktiv",
      "inactive": "Inaktiv",
      "group": "Gruppe",
      "create-update-group": "Erstelle/Aktualisiere eine Gruppe",
      "create-group": "Erstelle eine Gruppe",
      "update-group": "Aktualisiere eine Gruppe",
      "description-group": "Gruppenbeschreibung",
      "group-choice": "Gruppenauswahl",
      "select-group": "Wähle eine Gruppe aus",
      "vm-setup": "Virtuelle Umgebung",
      "vm-create": "Erstellen",
      "vm-delete": "Löschen",
      "vm-start": "Start",
      "vm-stop": "Stop",
      "vm-list": "Liste",
      "vm-status": "Status",
      "vm-config": "Konfiguration",
      "vm-id": "ID",
      "vm-app-id": "Anwendung ID",
      "vm-cores": "Kerne",
      "vm-memory-size": "Arbeitsspeicher",
      "vm-disk-size": "Festplattenspeicher",
      "vm-os-type": "Betriebssystem",
      "vm-id-instance": "ID Instanz",
      "vm-name": "Name",
      "vm-iso-installer": "ISO-Abbild",
      "vm-keyboard-language": "Tastatur (Sprache)",
      "vm-object-type": "Objekt Typ",
      "vm-desc": "Beschreibung",
      "vm-snapname": "Aufnahme",
      "vm-rollback": "Zurücksetzen",
      "vm-new-id": "Neue ID",
      "vm-disk": "Platte",
      "vm-config-pre": "Vor",
      "vm-config-post": "Nach",
      "vm-clone": "Klonen",
      "vm-template-convert": "Vorlage konvertieren",
      "vm-node-dhcp": "DHCP",
      "vm-node-iptables": "IPTables",
      "vm-ceph-pool": "Ceph Pool",
      "vm-ceph-public": "Ceph Öffentlich",
      "vm-ceph-shared": "Ceph Geteilt",
      "vm-ceph-user": "Ceph Benutzer",
      "vm-connect": "Verbindung",
      "vm-env": "Umgebung",
      "vm-file": "Datei",
      "vm-kill": "Prozess beenden",
      "vm-force": "Prozess beenden",
      "vm-command": "Befehl",
      "vm-type": "Typ",
      "vm-contype": "Verbindungstyp",
      "vm-resolution": "Auflösung",
      "vm-resize": "Größe ändern",
      "vm-scale": "Skala",
      "vm-arguments": "Argumente",
      "vm-mount": "Einbauen",
      "vm-unmount": "Ausbauen",
      "vm-execute": "Ausführen",
      "vm-instance": "Instanzen",
      "vm-instance-app": "Applikation",
      "vm-instance-command": "Befehl",
      "vm-instance-arguments": "Argumente",
      "vm-instance-ssh": "SSH",
      "vm-instance-status": "Aktion",
      "vm-instance-os-package": "Betriebssystem - Paket",
      "vm-instance-upload": "Hochladen",
      "vm-instance-resize": "Größe ändern",
      "vm-instance-mount-ceph": "Dateisystem",
      "vm-instance-connection-icmp": "Verbindung - ICMP",
      "vm-instance-connection-ssh": "Verbindung - SSH",
      "vm-instance-install": "Installation",
      "vm-instance-uninstall": "Deinstallation",
      "vm-instance-list": "Liste",
      "vm-connection-icmp": "Verbindung - ICMP",
      "vm-connection-ssh": "Verbindung - SSH",
      "vm-docker": "Docker",
      "vm-dockerfile": "Dockerfile",
      "vm-root-image": "Root Image",
      "vm-docker-daemon-info": "Daemon Info",
      "vm-docker-image-inspect": "Image überprüfen",
      "vm-docker-image-list": "Image Liste",
      "vm-docker-image-create": "Image erstellen",
      "vm-docker-image-create-root": "Root Image erstellen",
      "vm-docker-image-clone": "Image klonen",
      "vm-docker-image-delete": "Image löschen",
      "vm-docker-image-build": "Image bauen",
      "vm-docker-container-start": "Container starten",
      "vm-docker-container-stop": "Container beenden",
      "vm-docker-container-list": "Container Liste",
      "vm-docker-container-log": "Container Logs",
      "vm-phases": "Phases",
      "vm-phases-object-status": "Objekt Status",
      "vm-phases-object-list": "Objekt Liste",
      "vm-phases-environment-create": "Umgebung erstellen",
      "vm-phases-environment-finalize": "Umgebung finalisieren",
      "vm-phases-environment-delete": "Umgebung löschen",
      "vm-phases-environment-get": "Umgebung erhalten",
      "vm-phases-environments-get": "Umgebungen erhalten",
      "vm-phases-environment-start": "Umgebung starten",
      "vm-phases-environment-stop": "Umgebung beenden",
      "vm-phases-environment-run": "Umgebung laufen",
      "vm-phases-cfg-from-application": "CFG von Anwendung",
      "vm-phases-cfg-tasklist": "CFG Aufgabenliste",
      "vm-phases-cfg-applist": "CFG Anwendungsliste",
      "vm-phases-cfg-target": "CFG Ziel",
      "vm-phases-baseimage-create": "Baseimage erstellen",
      "vm-phases-baseimage-clone": "Baseimage klonen",
      "vm-phases-baseimage-create-from-app": "Baseimage von der Anwendung erstellen",
      "vm-phases-baseimage-clone-from-app": "Baseimage von der Anwendung klonen",
      "vm-phases-baseimage-finalize": "Baseimage finalisieren",
      "vm-phases-baseimage-delete": "Baseimage löschen",
      "vm-phases-baseimage-start": "Baseimage starten",
      "vm-phases-baseimage-stop": "Baseimage beenden",
      "viewer-connection": "Verbindung starten",
      "viewer-disconnection": "Verbindung starten",
      "viewer-settings": "Einstellungen",
      "viewer-contype": "Verbindungstyp",
      "viewer-resolution": "Auflösung",
      "viewer-check": "Verbindungen",
      "authentification": "Authentifizierung",
      "auth-token": "Token prüfen",
      "auth-user-id": "Benutzer ID",
      "no-data-available": "Keine Daten verfügbar",
      "request-successful": "Anfrage erfolgreich",
      "request-failed": "Anfrage fehlgeschlagen",
      "new-tab": "Neuer Tab",
      "new-window": "Neues Fenster",
      "execute-command": "Befehl ausführen",
      "os-install": "Installation",
      "os-uninstall": "Deinstallation",
      "demo": "Demo",
      "demo-overview": "Demo - Übersicht",
      "desktops": "Desktops",
      "desktop-details": "Desktop Details",
      "desktop-create": "Einen neuen Desktop erstellen",
      "desktop-groups": "Desktop-Gruppen",
      "desktop-groups-create": "Desktop-Gruppe erstellen",
      "desktop-groups-main-description": "Hauptbeschreibung",
      "desktop-main-description": "Hauptbeschreibung",
      "desktop-sub-description": "Detailbeschreibung",
      "desktop-groups-add-user-group": "Benutzergruppe zur Desktop-Gruppe hinzufügen",
      "add-user-group": "Benutzergruppe hinzufügen",
      "select-user-group": "Wähle eine Benutzergruppe aus",
      "admin-assign-object-to-user": "Objekt dem Benutzer zuweisen",
      "admin-assign-app": "Anwendungen zuweisen",
      "owner": "Besitzer",
      "admin-assign-object-app": "Objekte und Anwendungen zuweisen",
      "app-configuration": "Anwendungen - Konfiguration",
      "app-list": "Anwendungsliste",
      "choice-list": "Liste auswählen",
      "user-select": "Benutzer",
      "shared-select": "Geteilt",
      "both-select": "Beide",
      "app-create": "Anwendung erstellen",
      "app-create-shared": "Geteilte Anwendung erstellen",
      "app-update": "Anwendung aktualisieren",
      "app-update-shared": "Geteilte Anwendung aktualisieren",
      "app-delete-shared": "Lösche geteilte Anwendung",
      "app-delete": "Lösche Anwendung",
      "app-get": "Erhalte Anwendung",
      "app-create-update-name": "Name",
      "app-create-update-os-type": "Betriebssystem",
      "app-create-update-installer": "Installation",
      "app-create-update-installer-args": "Installation Argumente",
      "app-create-update-installer-type": "Installation Typ",
      "app-create-update-target": "Ziel",
      "app-create-update-target-args": "Ziel Argumente",
      "app-create-update-version": "Version",
      "app-installer-none": "Keine Auswahl",
      "app-installer-execute-command": "Befehl ausführen",
      "app-installer-os-install": "Betriebssystem installieren",
      "app-installer-os-uninstall": "Betriebssystem deinstallieren",
      "app-general": "Anwendungen",
      "app-shared": "Geteilte Anwendungen",
      "app-request": "Anfrage für Anwendung",
      "add-application": "Anwendung hinzufügen",
      "validate-registration": "Registrierung validieren",
      "user-code": "Code",
      "request-message": "Anfrage - Nachricht"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
