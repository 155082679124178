import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Header from "../../../components/header";

function Monitoring() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Header />
      <ReactBootstrap.Container id="monitoring">
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <div>
              <h2>
                {t('monitoring-overview')}
              </h2>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              Message: User 1 was created
            </div>
            <div>
              Message: User 2 was deleted
            </div>
            <div>
              Message: User 3 updated information
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <Link to="/dashboard" className="link-daas-design">
                {/*<i className="fa-solid fa-arrow-left"></i>*/}
                <div>
                  {t('back-link')}
                </div>
              </Link>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
    </>
  );
}

export default Monitoring;
