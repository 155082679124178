import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import {useMediaQuery} from "react-responsive";

import {DEVELOPMENT, TEST, LIVE, USERNAME, PASSWORD} from "../constants/constants";

import Logo from './../assets/images/png/DESIGNLogoColouredWhiteFont.png';

function Header(){
  const { t, i18n } = useTranslation();

  let userToken = localStorage.getItem("role");

  const isSmall = useMediaQuery({
    query: '(max-width: 767px)'
  })

  const changeLanguageGerman = () =>
  {
    i18n.changeLanguage("de")
    localStorage.setItem('language', 'de')
  }

  const changeLanguageEnglish = () =>
  {
    i18n.changeLanguage("en")
    localStorage.setItem('language', 'en')
  }

  const logoutValidation = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("language");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userTimeToken");
    localStorage.removeItem("userRefreshToken");
    localStorage.removeItem("user-group-id");
    localStorage.removeItem("desktop-group-id");

    setTimeout(async () => {
      const servicePartnerAuthLogout = async () => {
        await axios.post(DEVELOPMENT + '/logout', {
          username: USERNAME,
          password: PASSWORD,
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': true,
          },
          //withCredentials: true,
        })
            .then(response => {
              console.log(response);
            })
            .catch(e => {
              console.log(e)
            });
      };
      await servicePartnerAuthLogout();
    }, 1000);
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  };

  return (
    <>
      <ReactBootstrap.Navbar expand="lg" fixed="top" variant="dark">
        <ReactBootstrap.Container fluid={true} className={isSmall ? 'header-small' : ''}>
          <ReactBootstrap.Navbar.Brand>
            <img src={Logo} alt="Logo"/>
          </ReactBootstrap.Navbar.Brand>
          <ReactBootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
          <ReactBootstrap.Navbar.Collapse id="basic-navbar-nav">
            <ReactBootstrap.Nav className="justify-content-end" style={{ width: "100%" }}>
              {/*<ReactBootstrap.Nav.Link href="#home">Home</ReactBootstrap.Nav.Link>*/}
              <ReactBootstrap.NavDropdown title={t('language')} id="basic-nav-dropdown">
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                  EN
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                  DE
                </ReactBootstrap.NavDropdown.Item>
              </ReactBootstrap.NavDropdown>
              {
                userToken ? (
                  <ReactBootstrap.Nav.Link href="#" onClick={logoutValidation}>
                    {t('logout')}
                  </ReactBootstrap.Nav.Link>
                ) : (
                  <></>
                )
              }
            </ReactBootstrap.Nav>
          </ReactBootstrap.Navbar.Collapse>
        </ReactBootstrap.Container>
      </ReactBootstrap.Navbar>
    </>
  )
}

export default Header;
