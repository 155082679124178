import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import {DEVELOPMENT, URL_SYSTEM} from "../../../constants/constants";
import {Field, Form, Formik} from "formik";

function MainUser() {
  const [baseimageCreate, setBaseimageCreate] = useState(false);
  const [environmentCreate, setEnvironmentCreate] = useState(false);
  const [environmentApps, setEnvironmentApps] = useState([]);
  const [dockerfileEnvironmentCreate, setDockerfileEnvironmentCreate] = useState();
  const [dockerfileBaseImageCreate, setDockerfileBaseImageCreate] = useState();
  const [viewerURL, setViewerURL] = useState("");
  const [startApplicationSuccess, setStartApplicationSuccess] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const { t, i18n } = useTranslation();

  const chooseApplication = (applicationNameText) => {
    localStorage.setItem('application', applicationNameText)

    window.location.href = "/dashboard/startApplication";
  };


  const showApplicationModal = () => {
    setApplicationModal(true);
  };
  const closeApplicationModal = () => {
    setApplicationModal(false);
  };
  const changeRequestMessage = event => {
    setRequestMessage(event.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");

    /*axios.post(DEVELOPMENT + '/phases/baseimage_create', {
      id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
      obj_type: "container",
      name: "testappuser",
      rootimage: "x11vnc",
      dockerfile: dockerfileBaseImageCreate,
      ceph_public: "on",
      ceph_shared: "on",
      ceph_user: "on",
      viewer_resolution: "1280x800",
      viewer_contype: "instvnc",
      viewer_resize: "none",
      viewer_scale: "on",
    }, {
      headers: {
        'Authorization': token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': URL_SYSTEM,
        'Access-Control-Allow-Headers': URL_SYSTEM,
      },
      // withCredentials: true,
    })
    .then(response => {
      console.log(response);
      if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
        console.log("true and maybe object exist");

        axios.post(DEVELOPMENT + '/phases/cfg_tasklist', {
          id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
          env: "",
          tasklist: `[{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"","args":""},{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Word.exe\\\"","args":""},{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe\\\"","args":""}]`,
          type: "os_install",
          cmd: "\\\"C:/Program Files/Microsoft Office/root/Office16/PowerPoint.exe\\\"",
          args: "",
        }, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': URL_SYSTEM,
            'Access-Control-Allow-Headers': URL_SYSTEM,
          },
          // withCredentials: true,
        })
        .then(response => {
          console.log(response);
          if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
            console.log("true and maybe still exist");

            axios.post(DEVELOPMENT + '/phases/cfg_applist', {
              id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
              env: "",
              applist: `[{"name":"Word","cmd":"C:/Program Files/Microsoft Office/root/Office16/Word.exe","args":""},{"name":"Excel","cmd":"C:/Program Files/Microsoft Office/root/Office16/Excel.exe","args":""},{"name":"Powerpoint","cmd":"C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe","args":""}]`,
              cmd: `C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe`,
              args: "",
            }, {
              headers: {
                'Authorization': token,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': URL_SYSTEM,
                'Access-Control-Allow-Headers': URL_SYSTEM,
              },
              // withCredentials: true,
            })
            .then(response => {
              console.log(response);
              if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                console.log("true and maybe still exist");

                axios.post(DEVELOPMENT + '/phases/cfg_set_target', {
                  id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                  env: "",
                  target: `{"name":"testappusername","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"","args":""}`,
                  name: "testappuser",
                  cmd: "\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"",
                  args: "",
                }, {
                  headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': URL_SYSTEM,
                    'Access-Control-Allow-Headers': URL_SYSTEM,
                  },
                  // withCredentials: true,
                })
                .then(response => {
                  console.log(response);
                  if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                    console.log("true and maybe still exist");

                    axios.post(DEVELOPMENT + '/phases/baseimage_finalize', {
                      id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                    }, {
                      headers: {
                        'Authorization': token,
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': URL_SYSTEM,
                        'Access-Control-Allow-Headers': URL_SYSTEM,
                      },
                      // withCredentials: true,
                    })
                    .then(response => {
                      console.log(response);
                      if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                        console.log("true and maybe still exist");

                        setBaseimageCreate(true)

                        axios.post(DEVELOPMENT + '/phases/environment_create', {
                          id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                          name: "testappuser",
                          dockerfile: dockerfileEnvironmentCreate,
                        }, {
                          headers: {
                            'Authorization': token,
                            'Content-Type': 'multipart/form-data',
                            'Access-Control-Allow-Origin': URL_SYSTEM,
                            'Access-Control-Allow-Headers': URL_SYSTEM,
                          },
                          // withCredentials: true,
                        })
                        .then(response => {
                          console.log(response);
                          if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                            console.log("true and maybe still exist");

                            setEnvironmentApps([response.data.response_data.env_apps])

                            axios.post(DEVELOPMENT + '/phases/cfg_tasklist', {
                              id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                              env: "testappuser",
                              tasklist: `[{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"","args":""},{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Word.exe\\\"","args":""},{"type":"os_install","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe\\\"","args":""}]`,
                              type: "os_install",
                              cmd: "\\\"C:/Program Files/Microsoft Office/root/Office16/PowerPoint.exe\\\"",
                              args: "",
                            }, {
                              headers: {
                                'Authorization': token,
                                'Content-Type': 'multipart/form-data',
                                'Access-Control-Allow-Origin': URL_SYSTEM,
                                'Access-Control-Allow-Headers': URL_SYSTEM,
                              },
                              // withCredentials: true,
                            })
                            .then(response => {
                              console.log(response);
                              if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                                console.log("true and maybe still exist");

                                axios.post(DEVELOPMENT + '/phases/cfg_applist', {
                                  id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                                  env: "testappuser",
                                  applist: `[{"name":"Word","cmd":"C:/Program Files/Microsoft Office/root/Office16/Word.exe","args":""},{"name":"Excel","cmd":"C:/Program Files/Microsoft Office/root/Office16/Excel.exe","args":""},{"name":"Powerpoint","cmd":"C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe","args":""}]`,
                                  cmd: `C:/Program Files/Microsoft Office/root/Office16/Powerpoint.exe`,
                                  args: "",
                                }, {
                                  headers: {
                                    'Authorization': token,
                                    'Content-Type': 'multipart/form-data',
                                    'Access-Control-Allow-Origin': URL_SYSTEM,
                                    'Access-Control-Allow-Headers': URL_SYSTEM,
                                  },
                                  // withCredentials: true,
                                })
                                .then(response => {
                                  console.log(response);
                                  if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                                    console.log("true and maybe still exist");

                                    axios.post(DEVELOPMENT + '/phases/cfg_set_target', {
                                      id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                                      env: "testappuser",
                                      target: `{"name":"testappusername","cmd":"\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"","args":""}`,
                                      name: "testappuser",
                                      cmd: "\\\"C:/Program Files/Microsoft Office/root/Office16/Excel.exe\\\"",
                                      args: "",
                                    }, {
                                      headers: {
                                        'Authorization': token,
                                        'Content-Type': 'multipart/form-data',
                                        'Access-Control-Allow-Origin': URL_SYSTEM,
                                        'Access-Control-Allow-Headers': URL_SYSTEM,
                                      },
                                      // withCredentials: true,
                                    })
                                    .then(response => {
                                      console.log(response);
                                      if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                                        console.log("true and maybe still exist");

                                        axios.post(DEVELOPMENT + '/phases/environment_finalize', {
                                          id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
                                          name: "testappuser",
                                        }, {
                                          headers: {
                                            'Authorization': token,
                                            'Content-Type': 'multipart/form-data',
                                            'Access-Control-Allow-Origin': URL_SYSTEM,
                                            'Access-Control-Allow-Headers': URL_SYSTEM,
                                          },
                                          // withCredentials: true,
                                        })
                                        .then(response => {
                                          console.log(response);
                                          if ((response.status === 200 && response.data.response_code === 200) || (response.status === 200 && response.data.response_code !== 200)) {
                                            console.log("true and maybe still exist");

                                            setEnvironmentCreate(true);
                                          }
                                        })
                                        .catch(e => {
                                          console.log(e)

                                          setEnvironmentCreate(false);
                                        });
                                      }
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    });
                                  }
                                })
                                .catch(e => {
                                  console.log(e)
                                });
                              }
                            })
                            .catch(e => {
                              console.log(e)
                            });
                          }
                        })
                        .catch(e => {
                          console.log(e)
                        })
                      }
                    })
                    .catch(e => {
                      console.log(e)
                      setBaseimageCreate(false)
                    });
                  }
                })
                .catch(e => {
                  console.log(e)
                });
              }
            })
            .catch(e => {
              console.log(e)
            });
          }
        })
        .catch(e => {
          console.log(e)
        });
      }
    })
    .catch(e => {
      console.log(e)
    });*/
  }, [])

  const startApplicationUser = () => {
    const token = localStorage.getItem("userToken");

    axios.post(DEVELOPMENT + '/phases/environment_run', {
      id: "aaa-bbb-ccc-ddd-eee-win11-usertest",
      env: "testappuser",
      connect: "on",
    }, {
      headers: {
        'Authorization': token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': URL_SYSTEM,
        'Access-Control-Allow-Headers': URL_SYSTEM,
      },
      // withCredentials: true,
    })
    .then(response => {
      console.log(response);
      if (response.status === 200 && response.data.response_code === 200) {
        setStartApplicationSuccess(true);
        let xhr = new XMLHttpRequest();

        function handler() {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              console.log(this);
              console.log(this.responseURL);
              //let dataURL = URL.createObjectURL(new Blob([this.responseURL]));
              document.getElementById("viewer-desktop").srcdoc = this.response; // this.responseURL
            } else {
              console.error('not loaded');
            }
          }
        }

        xhr.open('GET', 'https://cluster.daas-design.de' + response.data.response_url);
        xhr.onreadystatechange = handler;
        //xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', localStorage.getItem('userToken'));
        xhr.setRequestHeader('Access-Control-Allow-Origin', URL_SYSTEM);
        xhr.setRequestHeader('Access-Control-Allow-Headers', URL_SYSTEM);
        xhr.send();

        /*window.addEventListener("message", (event) => {
            if (event.data.type === "DaaSCustomReloadEvent") {
                console.log("Received data from iframe:", event.data.data);
                doRequest();
            }
        });
        function doRequest(){
            let xhr = new XMLHttpRequest();
            function handler() {
                if (this.readyState === this.DONE) {
                    console.log(this);
                    console.log(this.responseURL);
                    document.getElementById("viewer-desktop").srcdoc = this.response;
                }
            }
            xhr.open('GET', response.data.response_url);
            xhr.onreadystatechange = handler;
            //xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', localStorage.getItem('userToken'));
            xhr.setRequestHeader('Access-Control-Allow-Origin', URL_SYSTEM);
            xhr.setRequestHeader('Access-Control-Allow-Headers', URL_SYSTEM);
            xhr.send();
        }*/

        setViewerURL('https://cluster.daas-design.de' + response.data.response_url);

      }
      if (response.status === 200 && response.data.response_code !== 200) {
        setViewerURL("");
        setStartApplicationSuccess(false);
      }
    })
    .catch(e => {
      console.log(e)
      setViewerURL("");
      setStartApplicationSuccess(false);
    });
  }

  return (
    <>
      <ReactBootstrap.Container id="dashboardUser">
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <h2>
              {t('overview')}
            </h2>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <ReactBootstrap.Row>
          {
            environmentApps.length > 0 ? environmentApps.maps(data => {
              return(
                <>
                  <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} xl={3} className="text-center" onClick={startApplicationUser}>
                    <div>
                      {
                        data.name.toLowerCase() === "word"
                          ? (
                            <i className="fa-regular fa-file-word"></i>
                          )
                          : data.name.toLowerCase() === "excel"
                            ? (
                              <i className="fa-regular fa-file-excel"></i>
                            ) : data.name.toLowerCase() === "powerpoint"
                              ? (
                                <i className="fa-regular fa-file-powerpoint"></i>
                              ) : (
                                <i className="fa-solid fa-circle-info"></i>
                              )
                      }
                    </div>
                    <div className="link-daas-design" onClick={startApplicationUser}>
                      <div>
                        {data.name}
                      </div>
                    </div>
                  </ReactBootstrap.Col>
                </>
              )
            }) : (
              <>
                {t('no-data-available')}
              </>
            )
          }
          <ReactBootstrap.Col xs={12} sm={12} md={6} lg={4} xl={3} className="text-center" onClick={() => setApplicationModal(true)}>
            <div>
              <i className="fa-solid fa-circle-plus"></i>
            </div>
            <div className="link-daas-design" onClick={() => setApplicationModal(true)}>
              <div>
                {t('add-application')}
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        {
          startApplicationSuccess ? (
            <>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    URL: {viewerURL}
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <iframe
                      id="viewer-desktop"
                      //allowFullscreen={true}
                      title="DESIGN - Desktop viewer"
                      sandbox="allow-scripts allow-same-origin"
                      name="sandbox"
                  ></iframe>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </>
          ) : (
              <></>
          )
        }
        {/* Modal for apps */}
        <ReactBootstrap.Modal
            show={applicationModal}
            onHide={closeApplicationModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title>
              {t('app-list')}
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Tabs defaultActiveKey="app-general" id="user-app-view">
              <ReactBootstrap.Tab eventKey="app-general" title={t('app-general')}>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                      Word
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                      Excel
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                      PowerPoint
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab eventKey="app-shared" title={t('app-shared')}>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                      Terminal
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div>
                      Adobe Photoshop
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab eventKey="app-request" title={t('app-request')}>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="request-message">{t('request-message')}</label>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <input type="text" id="request-message" name="request-message" value={requestMessage} onChange={changeRequestMessage}/>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ReactBootstrap.Button
                        type="submit"
                        variant="primary"
                        id="submit"
                        disabled={requestMessage.length > 0 ? false : true}
                        onClick={closeApplicationModal}>
                      {t('submit')}
                    </ReactBootstrap.Button>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Tab>
            </ReactBootstrap.Tabs>
          </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </ReactBootstrap.Container>
    </>
  );
}

export default MainUser;
